/* Reset CSS
* --------------------------------------- */
* {
  backface-visibility: hidden;
}

body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: #ffffff;
}
table {
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
  font-weight: normal;
  font-style: normal;
}
strong {
  font-weight: bold;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6,
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}

body {
  background: #000000;
}

@font-face {
  font-family: "Puu";
  src: url("./fonts/puu_sans-webfont.eot");
  src: url("./fonts/puu_sans-webfont.eot?#iefix") format("embedded-opentype"),
    url("./fonts/puu_sans-webfont.woff") format("woff"),
    url("./fonts/puu_sans-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

.welcome {
  opacity: 0;
}

.small {
  font-size:12px;
}

p {
  font-family: "Puu", sans-serif;
  color: #fff;
  padding: 10px 30px 0 20px;
  font-size:18px;
  line-height: 20px;
  text-align: center;
  max-width: 600px;
  margin:0 auto;
}

.welcome-p {
  font-family: "Puu", sans-serif;
  color: #fff;
  padding: 10px 30px 0 20px;
  font-size:18px;
  line-height: 20px;
  text-align: center;
  max-width: 800px !important;
  margin:0 auto;
}

.top {
  padding-top:30px;
}

.title {
  font-size: 35px;
    line-height: 32px;
    padding: 31px 0 15px 0;
}

.title-1 {
  font-family: "Raleway", sans-serif;
  font-size: 45px;
  padding-right: 55px;
  padding-bottom: 20px;
  line-height: 45px;
}

.body-1 {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
}

.title-2 {
  font-family: "Work Sans", sans-serif;
  font-weight: 700;
  font-size: 24px;
  padding-right: 55px;
  line-height: 24px;
}

.body-2 {
  font-family: "Work Sans", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

/* 
font-family: 'Raleway', sans-serif; 
font-family: 'Open Sans', sans-serif;
font-family: 'Work Sans', sans-serif;
*/

/* preload screen start */

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(0deg);
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  to {
    -ms-transform: translateX(-50%) translateY(-50%) rotate(360deg);
    -moz-transform: translateX(-50%) translateY(-50%) rotate(360deg);
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(360deg);
    -o-transform: translateX(-50%) translateY(-50%) rotate(360deg);
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

.welcome {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 700px;
  height: auto;
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.preload-ani {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 120px;
  height: auto;
  -ms-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.continue {
  position: absolute;
  left: 50%;
  bottom: 10px;
  -ms-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1000;
  -webkit-animation: action 1s infinite alternate;
  animation: action 1s infinite alternate;
}

@-webkit-keyframes action {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -10px);
  }
}

@keyframes action {
  0% {
    transform: translate(-50%, 0);
  }
  100% {
    transform: translate(-50%, -10px);
  }
}

.three-container {
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  top: 0;
  left: 0;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
}

.content-container {
  z-index: 10;
}

.trigger-one {
  width: 100%;
  height: 50px;
  background-color: darkgoldenrod;
  opacity: 0;
}

.trigger-two {
  width: 100%;
  height: 50px;
  background-color: rgb(46, 184, 11);
  opacity: 0;
}

.trigger-three {
  width: 100%;
  height: 50px;
  background-color: rgb(11, 86, 184);
  opacity: 0;
}

.trigger-four {
  width: 100%;
  height: 50px;
  background-color: rgb(155, 11, 184);
  opacity: 0;
}

.video-trigger-one {
  opacity: 0;
}

.video-trigger-two {
  opacity: 0;
}

.ui-container {
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 1000;
  top: 0;
  left: 0;
}

.depth {
  position: fixed;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  top: 5%;
  left: 2%;
}

.depth-indicator {
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  height: 5px;
  background-color: rgb(190, 86, 0);
  border-radius: 50%;
  opacity: 0;
}

.preload-overlay {
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 1010;
  top: 0;
  left: 0;
  background-color: #030303;
}

.info-overlay {
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 1005;
  top: 0;
  left: 0;
  background-color: #030303;
  visibility: hidden;
  opacity: 0;
}

.info-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.info-content {
  width: 100%;
  overflow-y: auto;
}

#style-3::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

#style-3::-webkit-scrollbar-thumb {
  background-color: #000000;
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 500px) {
  .title-1 {
    font-family: "Raleway", sans-serif;
    font-size: 33px;
    padding-right: 49px;
    padding-bottom: 20px;
    line-height: 35px;
  }

  .body-1 {
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
  }

  .title-2 {
    font-family: "Work Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    padding-right: 55px;
    line-height: 24px;
  }

  .body-2 {
    font-family: "Work Sans", sans-serif;
    font-size: 17px;
    font-weight: 400;
  }
}